.chatbot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #F9F9F9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 900px;
    margin: 0 auto;
    height: 100vh;
    overflow: hidden;
}

.chatbot-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    padding: 1rem;
    border-bottom: 2px solid #FF6600;
}

.chatbot-logo {
    width: 150px;
    height: auto;
}

.chat-history {
    flex: 1;
    padding: 16px;
    overflow-y: auto;
    background-color: #FFFFFF;
    border-bottom: 1px solid #FF6600;
}

.chat-message {
    margin-bottom: 12px;
    padding: 12px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.chat-message:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.chat-message p {
    margin: 0;
    line-height: 1.6;
    color: #000000;
}

/* Chat Input Container */
.chat-input-container {
    display: flex;
    padding: 12px;
    background-color: #FFFFFF;
    border-top: 1px solid #FF6600;
    width: 100%; /* Ensure the container takes full width */
    box-sizing: border-box;
}

/* Chat Input */
.chat-input {
    flex: 1;
    padding: 15px;
    border: 1px solid #FF6600;
    border-radius: 8px;
    margin-right: 8px;
    font-size: 16px;
    background-color: #fffde7;
    transition: background-color 0.3s ease;
    min-height: 80px; /* Minimum height */
    max-height: 150px; /* Maximum height to prevent excessive growth */
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    overflow-y: auto;
    resize: none;
}

/* Responsive: Larger screens */
@media (min-width: 768px) {
    .chat-input {
        min-height: 100px; /* Increase min height on larger screens */
        max-height: 200px; /* Increase max height on larger screens */
    }
}

/* Responsive: Smaller screens */
@media (max-width: 768px) {
    .chat-input {
        padding: 12px; /* Adjust padding for smaller screens */
        font-size: 14px; /* Slightly reduce font size */
        margin-right: 5px; /* Reduce the right margin */
    }
}

.chat-input:focus {
    background-color: #FFFFFF;
    outline: none;
}

.send-button {
    padding: 10px 20px;
    background-color: #FF6600;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.send-button:hover {
    background-color: #e65a00;
}

.send-button.disabled {
    background-color: #cccccc; /* Disable button color */
    cursor: not-allowed;
}
