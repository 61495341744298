/* Reset general */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: Arial, sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

/* Estructura general */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #000000; /* Color de texto principal en negro */
  background-color: #FFFFFF; /* Fondo blanco */
}

/* Encabezado */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF; /* Fondo blanco */
  padding: 1rem;
  border-bottom: 2px solid #000000; /* Línea sutil en negro */
}

.logo {
  width: 150px;
  height: auto;
}

.navbar ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.navbar a {
  text-decoration: none;
  color: #000000; /* Links en negro */
  font-weight: bold;
}

.navbar a:hover {
  color: #FF6600; /* Links en naranja al pasar sobre ellos */
}

/* Botones generales */
.fileInput, .emailInput, .instructionsInput, .loginButton, .saveButton, .copyButton {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #fffde7;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

.loginButton, .saveButton {
  background-color: #FF6600;
  color: #FFFFFF;
}

.loginButton:hover, .saveButton:hover {
  background-color: #e65a00; /* Oscurecer el botón naranja al pasar sobre él */
}

.copyButton {
  background-color: #00acc1;
  color: #FFFFFF;
}

.copyButton:hover {
  background-color: #00838f;
}

/* Sección de contenido principal */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #F9F9F9; /* Fondo gris muy claro */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 900px;
  margin: 0 auto;
}

.questionList {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.questionItem {
  background-color: #FFFFFF;
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.3s ease;
}

.questionItem:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.editor {
  width: 100%;
  margin-top: 0.5rem;
  border: 1px solid #FF6600;
  border-radius: 12px;
  background-color: #FFFFFF;
  padding: 1rem;
}

h1, h2, h3 {
  font-weight: bold;
  color: #000000;
}

p {
  line-height: 1.6;
  color: #000000;
}

/* Pie de página */
.footer {
  background-color: #000000;
  color: #FFFFFF;
  padding: 1rem;
  text-align: center;
  border-radius: 0 0 8px 8px;
  width: 100%;
}

.footer a {
  color: #FF6600; /* Enlaces en naranja */
  text-decoration: none;
}

.footer a:hover {
  color: #FFFFFF; /* Enlaces cambian a blanco al pasar sobre ellos */
}

/* Responsivo */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .container {
    padding: 1rem;
  }

  .fileInput, .emailInput, .instructionsInput {
    width: 100%;
  }
}
